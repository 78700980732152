import { supabase } from '../utils/supabase';

export const dashboardService = {
    async getYearlyStats(year, center = 'All Centers') {
        console.log(`Fetching stats for year: ${year}, center: ${center}`);
        
        let allImpactData = [];
        let page = 0;
        const pageSize = 1000;
        let hasMore = true;

        while (hasMore) {
            // Get all impact data for the year with pagination
            let query = supabase
                .from('impact')
                .select(`
                    *,
                    clients (
                        id,
                        gender,
                        minority_nonwhite_hispanic,
                        rural,
                        business_type,
                        center
                    )
                `)
                .eq('year', year)
                .range(page * pageSize, (page + 1) * pageSize - 1);

            // If a specific center is selected, filter by that center
            if (center && center !== 'All Centers') {
                query = query.eq('clients.center', center);
            }
                
            const { data: impactData, error: impactError } = await query;
            if (impactError) throw impactError;
            if (!impactData) break;

            allImpactData = [...allImpactData, ...impactData];
            
            // Check if we need to fetch more
            hasMore = impactData.length === pageSize;
            page++;
        }

        console.log('Total raw impact records count:', allImpactData.length);
        console.log('Sample impact record:', allImpactData[0]);

        // Filter out any records where clients is null
        const validImpactData = allImpactData.filter(i => i.clients !== null);
        console.log('Valid impact records count:', validImpactData.length);

        // Calculate totals from impact data
        let totalCapital = 0;
        let totalSales = 0;
        let totalJobs = 0;
        let totalHours = 0;
        let totalBusinessStarts = 0;

        validImpactData.forEach(record => {
            totalCapital += Number(record.capital_raised) || 0;
            totalSales += Number(record.sales_increase) || 0;
            totalJobs += Number(record.jobs_created) || 0;
            totalHours += Number(record.hours_count) || 0;
            totalBusinessStarts += Number(record.business_started) || 0;
        });

        // Get unique clients from impact data
        const uniqueClients = [...new Set(validImpactData.map(i => i.clients.id))];
        console.log('Unique clients count:', uniqueClients.length);

        // Log individual totals for verification
        console.log('Calculated totals:', {
            capital: totalCapital,
            sales: totalSales,
            jobs: totalJobs,
            hours: totalHours,
            businessStarts: totalBusinessStarts
        });

        const totals = {
            total_clients: uniqueClients.length,
            total_hours: totalHours,
            total_business_started: totalBusinessStarts,
            total_jobs_created: totalJobs,
            total_capital: totalCapital,
            total_sales_increase: totalSales,
            total_women: validImpactData.filter(i => i.clients?.gender === 'Female').length,
            total_minority: validImpactData.filter(i => i.clients?.minority_nonwhite_hispanic).length,
            total_rural: validImpactData.filter(i => i.clients?.rural).length
        };
        
        return totals;
    },

    async getAllYearlyStats() {
        const { data: impactData, error } = await supabase
            .from('impact')
            .select(`
                *,
                clients (
                    gender,
                    minority_nonwhite_hispanic,
                    rural,
                    business_type
                )
            `)
            .order('year', { ascending: false });
            
        if (error) throw error;
        return impactData;
    },

    async getAllCenters() {
        const { data, error } = await supabase
            .from('clients')
            .select('center')
            .not('center', 'is', null);
            
        if (error) throw error;
        
        // Get unique centers and sort them
        const uniqueCenters = [...new Set(data.map(client => client.center))]
            .sort((a, b) => a.localeCompare(b));
        
        return uniqueCenters;
    },

    async getClientsByCenter(center, year) {
        let query = supabase
            .from('clients')
            .select('id, business_type, center, created_at');

        // Apply filters
        if (center && center !== 'All Centers') {
            query = query.eq('center', center);
        }

        // Add created_at filter for the year if provided
        if (year) {
            const startDate = `${year}-01-01`;
            const endDate = `${year}-12-31`;
            query = query.gte('created_at', startDate).lte('created_at', endDate);
        }
            
        const { data, error } = await query;
        if (error) throw error;
        return data;
    },

    async getClientsByCounty(county) {
        const { data, error } = await supabase
            .from('clients')
            .select('*')
            .eq('county', county);
            
        if (error) throw error;
        return data;
    },

    async getClientsByIndustry(naicsCode) {
        const { data, error } = await supabase
            .from('clients')
            .select('*')
            .eq('naics_code', naicsCode);
            
        if (error) throw error;
        return data;
    },

    async getClientStats() {
        const { data, error } = await supabase
            .from('client_stats')
            .select('*')
            .single();
            
        if (error) throw error;
        return data;
    },

    // Calculate totals for a specific center
    calculateCenterTotals(clients, impactData) {
        const clientIds = clients.map(c => c.id);
        const centerImpact = impactData.filter(i => clientIds.includes(i.client_id));
        console.log('Center impact data:', centerImpact);

        const totals = {
            total_clients: clients.length,
            total_hours: centerImpact.reduce((sum, i) => {
                const hours = Number(i.hours_count) || 0;
                console.log('Center hours for impact:', i.id, hours);
                return sum + hours;
            }, 0),
            total_business_started: centerImpact.reduce((sum, i) => sum + (Number(i.business_started) || 0), 0),
            total_jobs_created: centerImpact.reduce((sum, i) => sum + (Number(i.jobs_created) || 0), 0),
            total_capital: centerImpact.reduce((sum, i) => sum + (Number(i.capital_raised) || 0), 0),
            total_sales_increase: centerImpact.reduce((sum, i) => sum + (Number(i.sales_increase) || 0), 0),
            total_women: centerImpact.filter(i => i.clients?.gender === 'Female').length,
            total_minority: centerImpact.filter(i => i.clients?.minority_nonwhite_hispanic).length,
            total_rural: centerImpact.filter(i => i.clients?.rural).length
        };

        console.log('Calculated center totals:', totals);
        return totals;
    }
}; 