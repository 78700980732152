import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, Typography, FormControl, InputLabel, Paper, Grid, Divider } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import PartnerImpact from './PartnerImpact';
import TrainingsEventsTable from './TrainingsEventsTable';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { dashboardService } from '../services/dashboardService';
import { supabase } from '../utils/supabase';

const data = [
  { source: 'Word of Mouth', percentage: '35%' },
  { source: 'Government Programs', percentage: '17%' },
  { source: 'Online', percentage: '13%' },
  { source: 'Other', percentage: '12%' },
  { source: 'SBA', percentage: '9%' },
  { source: 'Higher Ed', percentage: '7%' },
  { source: 'Chamber of Commerce', percentage: '3%' },
  { source: 'Lenders', percentage: '3%' },
  { source: 'Goldman Sachs', percentage: '1%' },
];

const ReferralSourceTable = () => {
  const filteredData = data.filter(row => row.source !== 'Not Set');

  return (
    <Box sx={{ width: '100%', padding: 0, mt: 2 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ backgroundColor: '#002d62', color: '#fff', padding: 3, borderRadius: '4px 4px 0 0' }}
      >
        Referral Source
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Referral Source</strong></TableCell>
              <TableCell align="right"><strong>%</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.source}</TableCell>
                <TableCell align="right">{row.percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const PartnerData = () => {
  const [data, setData] = useState(null);
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('All Centers');
  const [selectedYear, setSelectedYear] = useState(2024);
  const [totals, setTotals] = useState({});
  const [chartData, setChartData] = useState({ womenMinorityRural: {}, sectors: {} });
  const [trainings, setTrainings] = useState({});
  const [loading, setLoading] = useState(true);
  const [availableYears] = useState([2024]); // Only 2024 data available

  // Fetch centers once when component mounts
  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const allCenters = await dashboardService.getAllCenters();
        setCenters(allCenters);
      } catch (error) {
        console.error('Error fetching centers:', error);
      }
    };
    fetchCenters();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get all clients data for the selected center and year
        const allClientsData = await dashboardService.getClientsByCenter(selectedCenter, selectedYear);
        setData(allClientsData);

        // Get yearly stats which now includes impact data
        const yearlyStats = await dashboardService.getYearlyStats(selectedYear, selectedCenter);
        
        if (selectedCenter === 'All Centers') {
          calculateTotals(allClientsData, yearlyStats);
        } else {
          // For specific centers, we're already getting filtered data from getYearlyStats
          calculateTotals(allClientsData, yearlyStats);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCenter, selectedYear]);

  const calculateTotals = async (clientsData, yearlyStats) => {
    // First set the main totals
    const totals = {
      Clients: yearlyStats.total_clients || 0,
      Hours: yearlyStats.total_hours || 0,
      BusinessStarts: yearlyStats.total_business_started || 0,
      Jobs: yearlyStats.total_jobs_created || 0,
      Capital: yearlyStats.total_capital || 0,
      Sales: yearlyStats.total_sales_increase || 0,
    };

    const womenMinorityRural = {
      Women: yearlyStats.total_women || 0,
      Minority: yearlyStats.total_minority || 0,
      Rural: yearlyStats.total_rural || 0,
    };

    setTotals(totals);
    setChartData(prevState => ({
      ...prevState,
      womenMinorityRural
    }));

    try {
      const query = supabase
        .from('impact')
        .select(`
          clients (
            id,
            business_type
          )
        `)
        .eq('year', selectedYear);

      if (selectedCenter !== 'All Centers') {
        query.eq('clients.center', selectedCenter);
      }

      const { data: impactData, error } = await query;
      
      if (error) {
        console.error('Error fetching sectors:', error);
        return;
      }

      console.log('Impact data for sectors:', impactData);

      // Process NAICS sectors
      const sectors = {};
      const processedClients = new Set();

      impactData.forEach(impact => {
        const client = impact.clients;
        if (client && !processedClients.has(client.id) && client.business_type && client.business_type !== 'Not Set') {
          sectors[client.business_type] = (sectors[client.business_type] || 0) + 1;
          processedClients.add(client.id);
        }
      });

      console.log('Processed sectors:', sectors);

      // Sort sectors by count in descending order and limit to top 10
      const sortedSectors = {};
      Object.entries(sectors)
        .sort(([,a], [,b]) => b - a)
        .slice(0, 10) // Only show top 10 sectors
        .forEach(([key, value]) => {
          sortedSectors[key] = value;
        });

      console.log('Sorted sectors:', sortedSectors);

      // Update chart data with sectors
      setChartData(prevState => ({
        ...prevState,
        sectors: sortedSectors
      }));

    } catch (error) {
      console.error('Error processing sectors:', error);
    }
  };

  const handleCenterChange = (event) => {
    setSelectedCenter(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  if (loading) {
    return <p>Loading data...</p>;
  }

  const pieOptions = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const naicsPieOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{mb:4}}>Last Updated {new Date().toLocaleDateString()}</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Center</InputLabel>
            <Select value={selectedCenter} onChange={handleCenterChange} label="Center">
              <MenuItem value="All Centers">All Centers</MenuItem>
              {centers.map((center) => (
                <MenuItem key={center} value={center}>
                  {center}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Year</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange} label="Year">
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <PartnerImpact totals={totals} />
      <Divider sx={{my: 2}} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">SEDI Data</Typography>
            <Pie
              data={{
                labels: ['Women', 'Minority', 'Rural'],
                datasets: [
                  {
                    data: [
                      chartData.womenMinorityRural.Women,
                      chartData.womenMinorityRural.Minority,
                      chartData.womenMinorityRural.Rural
                    ],
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                  },
                ],
              }}
              options={pieOptions}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">Industry Sectors</Typography>
            <Pie
              data={{
                labels: Object.keys(chartData.sectors),
                datasets: [
                  {
                    data: Object.values(chartData.sectors),
                    backgroundColor: [
                      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                      '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                    ],
                  },
                ],
              }}
              options={naicsPieOptions}
            />
          </Paper>
        </Grid>
      </Grid>
      <ReferralSourceTable />
    </Box>
  );
};

export default PartnerData;