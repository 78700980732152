import React from 'react';
import Box from '@mui/material/Box';
import { PageWrapper } from "./PageWrapper";
import PDFViewer from "../components/PDFViewer";

export const OperationsManual = (props) => {
    const flipbookUrl = "https://utahsbdc.s3.us-west-2.amazonaws.com/documents/Exhibit+1.2+Operations+Manual.pdf";

    return (
        <PageWrapper title="Operations Manual" showSecondaryTitle={false} secondaryTitle={`2024`}>
            <Box sx={{mt: 2}}>
                <PDFViewer url={flipbookUrl} />
            </Box>
        </PageWrapper>
    );
};
