import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardContent, CardHeader, Divider, Typography, Box, Button, Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import the date adapter
import { PageWrapper } from "./PageWrapper";
import { fetchSuccessStories, updateStory } from "../services/apiService";

export const SuccessStoriesPage = () => {
  const { user } = useAuth0();
  const [stories, setStories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    const getSuccessStories = async () => {
      if (!user) {
        console.error('User is undefined');
        return;
      }
      if (!user.center) {
        console.error('User center is undefined');
        return;
      }
      try {
        const data = await fetchSuccessStories(user.center);
        setStories(data);
        console.log(data, 'success-stories', user.center);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getSuccessStories();
  }, [user]);

  const handleOpenDialog = (story) => {
    setSelectedStory(story);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStory(null);
  };

  const handleUpdateSteps = async () => {
    if (!selectedStory) return;

    try {
      const updatedStory = await updateStory(selectedStory.id, selectedStory.attributes);
      setStories((prevStories) =>
        prevStories.map((story) =>
          story.id === updatedStory.id
            ? { ...story, attributes: updatedStory.attributes }
            : story
        )
      );
    } catch (error) {
      console.error('Error updating steps:', error);
    } finally {
      handleCloseDialog();
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading success stories: {error.message}</p>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <PageWrapper title={"Success Stories"} showSecondaryTitle={true}>
        <div className="content-layout">
          <Divider sx={{ my: 2 }} />
          {stories.length === 0 ? (
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  No success story has been submitted.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Please have your client submit the intake form.
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  href="https://utahsbdc.org/client-success-story/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Utah SBDC Success Story Submission
                </Button>
              </CardContent>
            </Card>
          ) : (
            stories.map(story => (
              <Card key={story.id} sx={{ mb: 3 }}>
             
                {user.center.toLowerCase() === 'lead' && story.attributes.center?.data?.attributes?.Name && (
                  <CardHeader title={story.attributes.center.data.attributes.Name} sx={{ backgroundColor: '#efefef' }} />
                )}
    
                {user.center.toLowerCase() !== 'lead' && story.attributes.Year && (
                  <CardHeader title={story.attributes.Year} sx={{ backgroundColor: '#efefef' }} />
                )}
    
                <CardContent>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: { xs: 'column', md: 'row' }, 
                      alignItems: { xs: 'center', md: 'flex-start' }, 
                      justifyContent: { xs: 'center', md: 'flex-start' } 
                    }}
                  >
                    <Box>
                      <Typography variant="h2" sx={{ fontSize: 18, mb: 1 }}>
                        {story.attributes.businessName}
                      </Typography>
                     
                      <Stepper activeStep={-1} alternativeLabel>
                        <Step completed={story.attributes.mediaWaiver}>
                          <StepLabel>
                            Media Waiver Signed
                          </StepLabel>
                        </Step>
                        <Step completed={!!story.attributes.filmDate}>
                          <StepLabel>
                            Scheduled Film/Photo Date
                          </StepLabel>
                        </Step>
                        <Step completed={story.attributes.videoShot}>
                          <StepLabel>
                            Photography Completed
                          </StepLabel>
                        </Step>
                        <Step completed={story.attributes.articleCompleted}>
                          <StepLabel>
                            Article Writeup Completed
                          </StepLabel>
                        </Step>
                      </Stepper>

                      {story.attributes.filmDate && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                          <Typography sx={{fontWeight: 'bold'}}> Scheduled Film Date:</Typography> {new Date(story.attributes.filmDate).toLocaleString()}
                        </Typography>
                      )}
                      <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                        <Button 
                          variant="contained" 
                          color="primary"
                          onClick={() => window.open(story.attributes.submissionLink, '_blank')}
                        >
                          Access Google Drive
                        </Button>
                        {user.center.toLowerCase() === 'lead' && (
                          <Button variant="outlined" onClick={() => handleOpenDialog(story)}>
                            Update Progress
                          </Button>
                        )}
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        {story.attributes.vimeoReview ? (
                          <iframe 
                            src={`https://player.vimeo.com/video/${story.attributes.vimeoReview.split('/').pop()}`}
                            width="100%"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            title="Vimeo Review Video"
                          ></iframe>
                        ) : (
                          <Typography>No Vimeo Review URL available</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          )}
        </div>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Update Progress</DialogTitle>
          <DialogContent>
            {selectedStory && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStory.attributes.mediaWaiver}
                      onChange={(e) => setSelectedStory({
                        ...selectedStory,
                        attributes: { ...selectedStory.attributes, mediaWaiver: e.target.checked }
                      })}
                    />
                  }
                  label="Media Waiver Signed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStory.attributes.videoShot}
                      onChange={(e) => setSelectedStory({
                        ...selectedStory,
                        attributes: { ...selectedStory.attributes, videoShot: e.target.checked }
                      })}
                    />
                  }
                  label="Photography Completed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStory.attributes.articleCompleted}
                      onChange={(e) => setSelectedStory({
                        ...selectedStory,
                        attributes: { ...selectedStory.attributes, articleCompleted: e.target.checked }
                      })}
                    />
                  }
                  label="Article Writeup Completed"
                />
                <Box sx={{mt: 2}}>
                <DateTimePicker
  label="Scheduled Film Date & Time"
  value={selectedStory.attributes.filmDate || null}
  onChange={(newValue) => setSelectedStory((prevStory) => ({
    ...prevStory,
    attributes: { ...prevStory.attributes, filmDate: newValue }
  }))}
  renderInput={(params) => <TextField {...params} />}
/>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateSteps} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </PageWrapper>
    </LocalizationProvider>
  );
};