import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CountyChart from "../components/charts/CountyChart";
import CircularProgress from '@mui/material/CircularProgress';
import { PageWrapper } from "./PageWrapper";
import HeyzineViewer from "../components/HeyZineViewer";
import PDFViewer from "../components/PDFViewer";
import ChartComponent from "../components/ChartComponent";
import BenchmarkGraphs from "../components/BenchmarkingGraphs";
import TotalsDisplay from "../components/TotalsDisplay";
import ReportsTabs from "../components/ReportsTabs";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';


export const PartnerReports = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/.netlify/functions/fetchPartnerData');
      const jsonResponse = await response.json();
      console.log(jsonResponse);
    };

    fetchData();
  }, []);

  // Temporary maintenance mode flag
  const isUnderMaintenance = false;  // Set to false to restore normal functionality

  return (
    <PageWrapper title="Utah SBDC Network" showSecondaryTitle={true} secondaryTitle={`Quarterly Impact Report`}>
      {isUnderMaintenance ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '50vh',
            textAlign: 'center'
          }}
        >
          <Paper elevation={3} sx={{ p: 4, maxWidth: 600, width: '100%' }}>
            <UpdateIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Data Update in Progress
            </Typography>
            <Typography variant="body1" color="text.secondary">
              We are currently updating our quarterly impact reports with the latest data. Please check back soon.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
              Last Updated: February 24, 2025
            </Typography>
          </Paper>
        </Box>
      ) : (
        <ReportsTabs />
      )}
    </PageWrapper>
  );
};